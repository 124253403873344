import { useRef } from 'react'
import { Visibility } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import ValidationFlagsButton from '@/components/flags/ValidationFlagsButton'
import DocProjectTagsList from '../project-tags/DocProjectTagsList'
import { useDocumentContext } from '../image-zoom-pan/providers/DocumentProvider'

export type OptionsKeyType = keyof typeof options

const options = {
  relevant: 'Relevant Fields',
  all: 'All Fields',
  flagged: 'Fields with Flags',
}

export default function VisibleFieldsControl() {
  const overlay = useOverlay()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const { document } = useDocumentContext()

  const [selectedOptionKey, setSelectedOptionKey] = useLocalStorage<
    keyof typeof options
  >('validation-visible-fields-option', 'relevant')

  return (
    <>
      <ValidationFlagsButton
        selectedOptionKey={selectedOptionKey}
        setSelectedOptionKey={setSelectedOptionKey}
      />
      <Button
        startIcon={<Visibility />}
        onClick={overlay.toggle}
        ref={anchorRef}
        variant="text"
      >
        {options[selectedOptionKey]}
      </Button>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
        elevation={16}
      >
        {Object.entries(options).map(([key, value]) => (
          <MenuItem
            dense
            key={key}
            onClick={() => {
              setSelectedOptionKey(key as keyof typeof options)
              overlay.close()
            }}
            selected={key === selectedOptionKey}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>

      <Box sx={{ m: 'auto 0 !important', minWidth: 0 }}>
        <DocProjectTagsList docProjectTags={document?.doc_project_tags || []} />
      </Box>
    </>
  )
}
