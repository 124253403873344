import DocumentIndicator from './DocumentIndicator'
import { ErrorOutline } from '@mui/icons-material'
import { DocumentWorkflowState } from '@/types/document-workflow-states'
import { WorkflowState } from '@/types/workflows'
import { CircularProgress, Stack, Tooltip } from '@mui/material'
import { Document } from '@/types/documents'
import FlagsIndicator from '../flags/FlagsIndicator'

type DocumentRowIndicatorsProps = {
  document: Document
  workflowState: WorkflowState
  documentWorkflowState?: DocumentWorkflowState
  showPrimaryIndicators: boolean
}

const defaultFlagCount = { error: 0, warning: 0, info: 0, failure: 0 }

export default function DocumentRowIndicators({
  document,
  workflowState,
  documentWorkflowState,
  showPrimaryIndicators,
}: DocumentRowIndicatorsProps) {
  const {
    document_flags__count = defaultFlagCount,
    document_rows__document_row_values__row_value_flags__count = defaultFlagCount,
  } = document

  const totalFlagsCount = [
    ...Object.values(document_flags__count),
    ...Object.values(
      document_rows__document_row_values__row_value_flags__count,
    ),
  ].reduce((a, b) => a + b, 0)

  const indicators = []

  if (
    showPrimaryIndicators &&
    documentWorkflowState?.status === 'error' &&
    workflowState.code === 'ready_for_export'
  )
    indicators.push(
      <DocumentIndicator
        key="failed-to-export"
        message="Failed to Export"
        Icon={ErrorOutline}
        color="error"
      />,
    )

  if (documentWorkflowState?.status === 'exporting' && showPrimaryIndicators)
    indicators.push(
      <Tooltip key="exporting" title="Exporting">
        <Stack alignItems="center">
          <CircularProgress size={16} />
        </Stack>
      </Tooltip>,
    )

  if (totalFlagsCount > 0)
    indicators.push(
      <FlagsIndicator
        key="flag-indicator"
        documentId={document.id}
        flagsCount={{
          documentFlags: document_flags__count,
          rowsFlags: document_rows__document_row_values__row_value_flags__count,
        }}
      />,
    )

  return (
    <Stack direction="row" alignItems="center">
      {indicators}
    </Stack>
  )
}
