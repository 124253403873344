import { Dispatch, RefObject, SetStateAction, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import {
  Rotate90DegreesCw,
  Deselect,
  KeyboardAltOutlined,
  PivotTableChart,
  MyLocation,
  Preview,
  Leaderboard,
  MoreVert,
} from '@mui/icons-material'
import { IconButton, Stack, Tooltip } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { altHotKeyStr, modHotKeyStr } from '@/utils/get-os'
import DocumentThumbnailsPopover from '@/components/document-thumbnails/DocumentThumbnailsPopover'
import KeyboardShortcutsTooltip from '@/components/keyboard-shortcuts-tooltip/KeyboardShortcutsTooltip'
import KeyboardShortcutsDialog from '@/components/keyboard-shortcuts-popover/KeyboardShortcutsDialog'
import { useImageZoomPanCenterContext } from '@/components/image-zoom-pan/providers/ImageZoomPanCenterProvider'
import { useDocumentImagePageContext } from './providers/DocumentImagePageProvider'
import PaginationControls from './PaginationControls'
import ValidationOptionsPopover from './ValidationOptionsPopover'

const hotKeyConfig = {
  preventDefault: true,
  enableOnFormTags: true,
}

type ImageZoomPanControlsProps = {
  hidePaginationControls: boolean
  transformerRef: RefObject<ReactZoomPanPinchRef>
  rotateImage: () => void
  layout?: 'right' | 'left' | 'top' | 'bottom'
  setDrawBoxes?: Dispatch<SetStateAction<boolean>>
  setShowConfidence?: Dispatch<SetStateAction<boolean>>
  setValidationLayout?: Dispatch<
    SetStateAction<'right' | 'left' | 'top' | 'bottom'>
  >
}

export default function ImageZoomPanControls({
  hidePaginationControls,
  transformerRef,
  rotateImage,
  layout,
  setDrawBoxes,
  setShowConfidence,
  setValidationLayout,
}: ImageZoomPanControlsProps) {
  const optionsButtonRef = useRef<HTMLButtonElement>(null)
  const thumbnailsButtonRef = useRef<HTMLButtonElement>(null)

  const keyboardShortcutsOverlay = useOverlay()
  const optionsOverlay = useOverlay()
  const { thumbnailsOverlay } = useDocumentImagePageContext()

  const { centeredScaleState, centeredPositionXState } =
    useImageZoomPanCenterContext()

  const toggleLayout = () => {
    setValidationLayout?.((currentLayout) => {
      if (currentLayout === 'right') return 'bottom'
      if (currentLayout === 'bottom') return 'left'
      if (currentLayout === 'left') return 'top'
      if (currentLayout === 'top') return 'right'
      return 'right'
    })
  }

  useHotkeys(
    'alt+v',
    ({ type }) => {
      setDrawBoxes?.(type === 'keyup')
    },
    {
      ...hotKeyConfig,
      keyup: true,
      keydown: true,
    },
    [],
  )

  useHotkeys('alt+o', toggleLayout, { ...hotKeyConfig }, [layout])

  return (
    <Stack direction="row" spacing={0.5}>
      <IconButton onClick={rotateImage} size="small">
        <Rotate90DegreesCw fontSize="inherit" />
      </IconButton>

      <>
        <KeyboardShortcutsTooltip
          title="Hold to Hide Chips"
          enterDelay={1000}
          disableInteractive
          arrow
          keyboardShortcuts={[`${altHotKeyStr} + V`]}
        >
          <IconButton
            onMouseDown={() => {
              setDrawBoxes?.(false)
            }}
            onMouseUp={() => {
              setDrawBoxes?.(true)
            }}
            onMouseLeave={() => {
              setDrawBoxes?.(true)
            }}
            size="small"
            sx={{
              ':active': {
                background: (theme) => theme.palette.secondary.main,
              },
            }}
          >
            <Deselect fontSize="inherit" />
          </IconButton>
        </KeyboardShortcutsTooltip>
        <Tooltip
          title="Hold to Show OCR Confidence Levels"
          enterDelay={1000}
          disableInteractive
          arrow
        >
          <IconButton
            onMouseDown={() => {
              setShowConfidence?.(true)
            }}
            onMouseUp={() => {
              setShowConfidence?.(false)
            }}
            onMouseLeave={() => {
              setShowConfidence?.(false)
            }}
            size="small"
            sx={{
              ':active': {
                background: (theme) => theme.palette.secondary.main,
              },
            }}
          >
            <Leaderboard fontSize="inherit" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Shortcuts" arrow disableInteractive enterDelay={1000}>
          <IconButton
            size="small"
            onClick={() => {
              keyboardShortcutsOverlay.open()
            }}
          >
            <KeyboardAltOutlined fontSize="inherit" />
          </IconButton>
        </Tooltip>

        <KeyboardShortcutsTooltip
          title="Toggle Layout"
          arrow
          disableInteractive
          enterDelay={1000}
          keyboardShortcuts={[`${altHotKeyStr} + O`]}
        >
          <IconButton size="small" onClick={toggleLayout}>
            <PivotTableChart fontSize="inherit" />
          </IconButton>
        </KeyboardShortcutsTooltip>
      </>
      <Tooltip
        title="Center on Document"
        arrow
        disableInteractive
        enterDelay={1000}
      >
        <IconButton
          size="small"
          onClick={() => {
            transformerRef.current?.setTransform(
              centeredPositionXState[0],
              0,
              centeredScaleState[0],
            )
          }}
        >
          <MyLocation fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Tooltip
        title="See Thumbnails"
        arrow
        disableInteractive
        enterDelay={1000}
      >
        <IconButton
          size="small"
          onClick={thumbnailsOverlay.open}
          ref={thumbnailsButtonRef}
        >
          <Preview fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Tooltip title="View Options" arrow disableInteractive enterDelay={1000}>
        <IconButton
          size="small"
          onClick={() => optionsOverlay.open()}
          ref={optionsButtonRef}
        >
          <MoreVert fontSize="inherit" />
        </IconButton>
      </Tooltip>

      {!hidePaginationControls && <PaginationControls />}

      <KeyboardShortcutsDialog
        overlay={keyboardShortcutsOverlay}
        keyboardShortcutGroups={[
          {
            title: 'Document Shortcuts',
            shortcuts: {
              'Close Document': 'Esc',
              'Toggle Navigation Menu': `${altHotKeyStr} + L`,
              'Previous Document': `${altHotKeyStr} + 1`,
              'Next Document': `${altHotKeyStr} + 2`,
              'Previous Page': `${altHotKeyStr} + 3 / ${modHotKeyStr} + Scroll Up`,
              'Next Page': `${altHotKeyStr} + 4 / ${modHotKeyStr} + Scroll Down`,
              'Primary Action': `${altHotKeyStr} + D`,
              'Stay on Document on Event': 'Hold Shift',
              'Toggle Layout': `${altHotKeyStr} + O`,
              'Hold to Hide Chips': `${altHotKeyStr} + V`,
            },
          },
          {
            title: 'Field Shortcuts',
            shortcuts: {
              'Clear Selected Field Chips': `${modHotKeyStr} + Del`,
              'Focus on First Field': `${altHotKeyStr} + Tab`,
              'Multi-Select': 'Shift + Click and Drag',
              'Multi-Deselect': `Shift + ${altHotKeyStr} + Click and Drag`,
            },
          },
          {
            title: 'Table Shortcuts',
            shortcuts: {
              'Insert Row Above': `${modHotKeyStr} + ${altHotKeyStr} + Shift + Up`,
              'Insert Row Below': `${modHotKeyStr} + ${altHotKeyStr} + Shift + Down`,
              'Delete Row': `${modHotKeyStr} + ${altHotKeyStr} + Shift + Del`,
              'Smart Row Multi-Select': `${modHotKeyStr} + ${altHotKeyStr} + Click and Drag`,
            },
          },
        ]}
      />

      <DocumentThumbnailsPopover
        overlay={thumbnailsOverlay}
        layout={layout}
        anchorEl={thumbnailsButtonRef.current}
      />

      <ValidationOptionsPopover
        overlay={optionsOverlay}
        anchorEl={optionsButtonRef.current}
      />
    </Stack>
  )
}
