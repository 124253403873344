import { ReactNode } from 'react'
import {
  Menu,
  MenuItem,
  MenuProps,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import {
  Delete,
  Link as LinkIcon,
  ContentCopy,
  OpenInNew,
  Refresh,
  Download,
  Rule,
  Description,
} from '@mui/icons-material'
import { WorkflowState } from '@/types/workflows'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import {
  useDeleteDocument,
  useRunRules,
} from '@/service-library/hooks/documents'
import { useReprocessDocuments } from '@/service-library/hooks/process-documents'
import queryKeys from '@/service-library/query-keys'
import { handleDownloadOriginalDocument } from '@/utils/download-file'
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from '@/utils/snackbars'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import WorkflowStateDescriptionDialog from '@/components/workflow-state-page/WorkflowStateDescriptionDialog'
import copyToClipboard from './copy-to-clipboard'

type DocumentActionsMenuProps = {
  /** Overlay state. */
  overlay: OverlayState

  /** ID the project belongs to. */
  projectId: string

  /** The workflow state the document currently belongs to that's relevant to show. */
  workflowState?: WorkflowState

  /** Target document ID. */
  documentId?: string

  /** Callback that runs before the document is deleted. */
  onBeforeDelete?: () => void

  /** Callback that runs after the document is successfully deleted. */
  onDelete?: () => void

  /** Callback that runs when reprocess is clicked. */
  onReprocess?: () => void

  /** Any additional menu items can be passed in as children. */
  children?: ReactNode
} & Partial<MenuProps>

export default function DocumentActionsMenu({
  overlay,
  children,
  projectId,
  documentId,
  onBeforeDelete,
  onDelete,
  onReprocess,
  workflowState,
  ...rest
}: DocumentActionsMenuProps) {
  const { getCurrentPathWithParams } = useLocationHelpers()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const documentationOverlay = useOverlay()

  const { deleteDocument } = useDeleteDocument({
    sideEffectQueryKeys: [
      queryKeys.documents.all, // dashboard, validation, or document dialog
      queryKeys.documentRows.lists(), // dashboard
      queryKeys.documentWorkflowStates.details(), // count
    ],
    onSuccess: () => {
      showSuccessSnackbar('Document Deleted')
      onDelete?.()
    },
    onError: () => {
      showErrorSnackbar('Failed to delete document. Please try again later.')
    },
  })

  const { reprocessDocuments } = useReprocessDocuments({
    sideEffectQueryKeys: [
      queryKeys.documents.all, // dashboard, validation, or document dialog
      queryKeys.documentRows.lists(), // dashboard
      queryKeys.documentWorkflowStates.details(), // count
    ],
    onMutate: () => {
      onReprocess?.()
    },
    onError: () => {
      showErrorSnackbar('Failed to reprocess document. Please try again later.')
    },
  })

  const { runRules } = useRunRules({
    workflowId: selectedWorkflow.id,
    sideEffectQueryKeys: [
      queryKeys.documents.all, // dashboard, validation
      queryKeys.documentRows.lists(), // dashboard
      queryKeys.documentRowValues.lists(), // validation tables
    ],
    onError: () => {
      showErrorSnackbar('Document validation failed. Please try again later.')
    },
  })

  function handleDeleteDocument() {
    if (!documentId) return
    onBeforeDelete?.()
    deleteDocument(documentId)

    overlay.close()
  }

  function handleReprocessDocument() {
    if (!documentId) return
    showInfoSnackbar('Reprocessing Started')

    reprocessDocuments([
      {
        id: documentId,
        document_id: documentId,
      },
    ])

    overlay.close()
  }

  function handleReRunRules() {
    if (!documentId) return
    showInfoSnackbar('Validating Document')
    runRules({ id: documentId })
    overlay.close()
  }

  return (
    <>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={overlay.anchorEl as Element}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        {...rest}
      >
        {children}

        {workflowState && (
          <MenuItem
            onClick={() => {
              if (!documentId) return
              documentationOverlay.open()
              overlay.close()
            }}
          >
            <ListItemIcon>
              <Description />
            </ListItemIcon>
            <ListItemText>Workflow State Documentation</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            if (!documentId) return
            copyToClipboard(
              `${
                window.location.host
              }${getCurrentPathWithParams()}&document_id=${documentId}`,
            )
            showInfoSnackbar('Document link copied to clipboard')
            overlay.close()
          }}
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText>Copy Link</ListItemText>
        </MenuItem>

        <MenuItem
          component={Link}
          target="_blank"
          href={`${getCurrentPathWithParams()}&document_id=${documentId}`}
          onClick={overlay.close}
        >
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          <ListItemText>Open in New Tab</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            if (!documentId) return
            handleDownloadOriginalDocument(documentId)
            overlay.close()
          }}
        >
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            if (!documentId) return
            copyToClipboard(documentId)
            showInfoSnackbar('Document ID copied to clipboard')
            overlay.close()
          }}
        >
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Copy ID</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleReRunRules()
          }}
        >
          <ListItemIcon>
            <Rule />
          </ListItemIcon>
          <ListItemText>Re-Run Rules</ListItemText>
        </MenuItem>

        {selectedWorkflow.code === 'basic' && (
          <MenuItem
            onClick={() => {
              handleReprocessDocument()
            }}
          >
            <ListItemIcon>
              <Refresh />
            </ListItemIcon>
            <ListItemText>Reprocess</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleDeleteDocument()
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      {workflowState && (
        <WorkflowStateDescriptionDialog
          overlay={documentationOverlay}
          projectId={projectId}
          workflowState={workflowState}
        />
      )}
    </>
  )
}
