import axios from './auth-v2-axios'
import { Document } from '@/types/documents'
import PaginatedResponse from '@/types/paginated-response'

type GetDocumentsOptions = {
  documentIds: string[]
  limit?: string
  fieldsOnly?: string
  flagCodes?: string[]
  includeWorkflowStates?: boolean
}

export function getDocumentsForSet({
  documentIds,
  includeWorkflowStates,
  limit = '40',
  flagCodes = [],
  fieldsOnly,
}: GetDocumentsOptions) {
  const searchParams = new URLSearchParams({
    id__in: documentIds.join(','),
    limit,
    workflow_states__isnull: 'false',
  } as Record<string, string>)

  const fieldsToInclude = []

  if (includeWorkflowStates) {
    fieldsToInclude.push('workflow_states_ids')
  }
  if (fieldsToInclude.length > 0) {
    searchParams.append('fields__include', fieldsToInclude.join(','))
  }
  if (flagCodes.length > 0) {
    searchParams.append('any_flag__flag_level__in', flagCodes.join())
  }
  if (fieldsOnly) {
    searchParams.append('fields__only', fieldsOnly)
  }

  return axios.get<PaginatedResponse<Document>>(
    `/v2/pd/documents?${searchParams.toString()}`,
  )
}

export function getDocumentOriginalFileData({
  documentId,
}: {
  documentId: string
}) {
  return axios.get<Pick<Document, 'file_url_get' | 'file_ext' | 'name'>>(
    `/v2/pd/documents/${documentId}?fields__only=file_url_get,file_ext,name`,
  )
}
