import { useNavigate } from 'react-router-dom'
import { Delete, Edit } from '@mui/icons-material'
import { Button, Container, Stack, Tooltip } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { useDeleteWorkflow } from '@/service-library/hooks/workflows'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import LargeHeading from '@/components/large-heading/LargeHeading'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import EditWorkflowDialog from './AddEditWorkflowDialog'
import DeleteWorkflowDialog from './DeleteWorkflowDialog'

const systemWorkflows = ['basic', 'labeling', 'categorization']

export default function WorkflowSettings() {
  const navigate = useNavigate()
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { queryKey } = useWorkflowsContext()
  const editOverlay = useOverlay()
  const deleteOverlay = useOverlay()
  const isSystemWorkflow = systemWorkflows.includes(selectedWorkflow.code)

  const { deleteWorkflow } = useDeleteWorkflow({
    listQueryKey: queryKey,
    onMutate: () => {
      navigate('../general')
    },
    onSuccess: () => {
      showSuccessSnackbar('Workflow Deleted')
    },
    onError: () => {
      showErrorSnackbar('Unable to delete workflow. Please try again later.')
    },
  })

  return (
    <Container maxWidth="md" sx={{ height: '100%', py: 1 }}>
      <ProjectBreadcrumb label="Workflow General" url="../workflow-general" />

      <Stack justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack spacing={4}>
          <LargeHeading
            heading={selectedWorkflow.name}
            subHeading="Workflow Settings"
            actions={
              <>
                <Button
                  variant="text"
                  startIcon={<Edit />}
                  onClick={editOverlay.open}
                >
                  Edit
                </Button>
                <Tooltip
                  enterDelay={1000}
                  placement="bottom-start"
                  title={
                    isSystemWorkflow
                      ? 'This workflow cannot be deleted. If you need this workflow removed, contact Pixydocs support.'
                      : ''
                  }
                >
                  <div>
                    <Button
                      disabled={isSystemWorkflow}
                      variant="text"
                      startIcon={<Delete />}
                      color="error"
                      onClick={deleteOverlay.open}
                    >
                      Delete
                    </Button>
                  </div>
                </Tooltip>
                <CopyIDButton stringToCopy={selectedWorkflow.id} />
              </>
            }
          />
          <EditWorkflowDialog
            overlay={editOverlay}
            workflow={selectedWorkflow}
          />
          <DeleteWorkflowDialog
            name={selectedWorkflow.name}
            overlay={deleteOverlay}
            onDelete={() => {
              deleteOverlay.close()
              deleteWorkflow(selectedWorkflow.id)
            }}
          />
        </Stack>
      </Stack>
    </Container>
  )
}
