import { useState } from 'react'
import Markdown from 'react-markdown'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { Edit, Delete, Check } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { Workflow, WorkflowState } from '@/types/workflows'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import queryKeys from '@/service-library/query-keys'
import { useGetWorkflowStateEvents } from '@/service-library/hooks/workflow-state-events'
import { useUpdateWorkflow } from '@/service-library/hooks/workflows'
import {
  useDeleteWorkflowState,
  useUpdateWorkflowState,
} from '@/service-library/hooks/workflow-states'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import LargeHeading from '@/components/large-heading/LargeHeading'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import CodeEditorDialog from '@/components/code-editor/CodeEditorDialog'
import { useDocumentCount } from '@/components/document-count-provider/DocumentCountProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import { getWorkflowStateEventGroups } from '@/components/workflow-state-events-page/helpers'
import AddEditWorkflowStateDialog from '@/components/workflow-states-page/AddEditWorkflowStateDialog'
import DeleteWorkflowStateDialog from './DeleteWorkflowStateDialog'
import WorkflowStateRelevantFields from './WorkflowStateRelevantFields'
import useLocationHelpers from '@/hooks/useLocationHelpers'

type WorkflowStateDetailsProps = {
  workflow: Workflow
  workflowState: WorkflowState
  detailQueryKey: QueryKey
}

export default function WorkflowStateDetails({
  workflow,
  workflowState,
  detailQueryKey,
}: WorkflowStateDetailsProps) {
  const featureFlags = useFeatureFlagContext()
  const navigate = useNavigate()
  const { appendCurrentParamsToUrl } = useLocationHelpers()
  const { hasEditingPermission } = usePermission()

  const { queryKey } = useWorkflowsContext()

  const { updateWorkflow } = useUpdateWorkflow({
    listQueryKey: queryKey,
  })

  const { updateWorkflowState } = useUpdateWorkflowState({
    sideEffectQueryKeys: [
      queryKeys.workflows.lists(), // Update it in case they've already fetched it on other pages
    ],
    detailQueryKey,
    onError: () => {
      showErrorSnackbar(
        'Unable to update workflow state. Please try again later.',
      )
    },
    onSuccess: () => {
      showSuccessSnackbar('Description Updated')
    },
  })

  const editOverlay = useOverlay()
  const deleteOverlay = useOverlay()

  const canEditWorkflowState = hasEditingPermission('edit_projects')

  const { workflowStateEvents = [] } = useGetWorkflowStateEvents({
    filters: {
      workflow_state_id: workflowState.id,
      fields__include: 'actions',
      actions__fields__only: 'id,action_type',
      actions__action_type__fields__only: 'code',
    },
  })

  const { countByWorkflowStateId } = useDocumentCount()
  const { total } = countByWorkflowStateId[workflowState.id] || {}

  const { deleteWorkflowState } = useDeleteWorkflowState({
    sideEffectQueryKeys: [queryKeys.workflowStates.lists()],
    onError: () => {
      showErrorSnackbar(
        'Unable to delete workflow state. Please try again later.',
      )
    },
    onSuccess: () => {
      showSuccessSnackbar('Workflow State Deleted')
    },
  })

  const handleDeleteWorkflowState = () => {
    deleteWorkflowState(workflowState.id)
    navigate(appendCurrentParamsToUrl('../workflow-states'))
  }

  const getThemeColor = useGetThemeColor()

  const isInitialState =
    workflow?.initial_workflow_state_id === workflowState.id

  function handleSetInitialState() {
    if (!workflow) {
      return
    }
    updateWorkflow({
      ...workflow,
      initial_workflow_state_id: workflowState.id,
    })
  }

  const descriptionOverlay = useOverlay()
  const [descriptionValue, setDescriptionValue] = useState(
    workflowState.description || '',
  )

  const { systemEvents, customEvents } =
    getWorkflowStateEventGroups(workflowStateEvents)

  const orderedWorkflowStateEvents = [...systemEvents, ...customEvents]

  return (
    <Stack spacing={4}>
      <LargeHeading
        heading={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box
              sx={{
                background: getThemeColor(workflowState.color),
                width: 4,
                height: 24,
                borderRadius: 4,
              }}
            />
            <Box>{workflowState.name}</Box>
          </Stack>
        }
        subHeading="Workflow State"
        actions={
          <>
            {canEditWorkflowState &&
              !['available_for_training', 'training_batch'].includes(
                workflowState.code,
              ) && (
                <>
                  <Button
                    variant="text"
                    startIcon={<Edit />}
                    onClick={editOverlay.open}
                  >
                    Edit
                  </Button>
                  <Tooltip
                    title={
                      <>
                        {isInitialState && (
                          <div>Cannot delete the initial workflow state.</div>
                        )}

                        {total > 0 && (
                          <div>
                            Cannot delete workflow states that have documents
                            assigned to them.
                          </div>
                        )}
                      </>
                    }
                    PopperProps={{
                      sx: {
                        display:
                          total > 0 || isInitialState ? undefined : 'none',
                      },
                    }}
                  >
                    {/* Tooltip automatically hides if it's main child is disabled, so we need to wrap it with a Box */}
                    <Box>
                      <Button
                        variant="text"
                        color="error"
                        startIcon={<Delete />}
                        onClick={deleteOverlay.open}
                        disabled={total > 0 || isInitialState}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Tooltip>
                </>
              )}
            <CopyIDButton stringToCopy={workflowState.id} />
          </>
        }
      />

      <Stack spacing={1}>
        <Box>
          <Typography variant="h6">Events</Typography>
          <Typography variant="caption" component="div" color="text.secondary">
            Events and actions are the building blocks of automating quality
            control and the flow of documents.
          </Typography>
        </Box>

        <Box>
          <Button
            variant="text"
            component={RouterLink}
            to={appendCurrentParamsToUrl('./events')}
          >
            Edit Events
          </Button>
        </Box>

        <Stack spacing={1} sx={{ maxWidth: 400, py: 1 }}>
          {orderedWorkflowStateEvents.map((event) => {
            const actions = featureFlags.show_action_python_script
              ? event.actions
              : event.actions?.filter(
                  ({ action_type }) =>
                    action_type?.code !== 'custom_python_script',
                )
            return (
              <Stack key={event.id} spacing={1} direction="row">
                <Typography
                  variant="body1"
                  sx={{ flexGrow: 1 }}
                  alignItems="center"
                >
                  {event.name}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ flexShrink: 0 }}
                >
                  {actions?.length} action
                  {actions?.length !== 1 && 's'}
                </Typography>
              </Stack>
            )
          })}
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Box>
          <Typography variant="h6">Initial Workflow State</Typography>
          <Typography variant="caption" component="div" color="text.secondary">
            When set as the initial state, newly processed documents will be
            sent to this workflow state.
          </Typography>
        </Box>
        <Box>
          <Button
            variant="text"
            disabled={isInitialState}
            startIcon={isInitialState ? <Check /> : null}
            onClick={handleSetInitialState}
          >
            {isInitialState ? 'Initial State' : 'Set as Initial State'}
          </Button>
        </Box>
      </Stack>

      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6">Description</Typography>
          <Button
            variant="text"
            size="small"
            startIcon={<Edit />}
            onClick={descriptionOverlay.open}
          >
            Edit
          </Button>
        </Stack>
        <Card>
          <Box sx={{ p: 2, py: workflowState?.description ? 0 : 1.5 }}>
            {workflowState?.description ? (
              <Markdown
                components={{
                  a: ({ children, href }) => (
                    <Link color="primary" href={href} target="_blank">
                      {children}
                    </Link>
                  ),
                }}
              >
                {workflowState?.description}
              </Markdown>
            ) : (
              <Typography variant="caption" color="text.secondary">
                <i>Click &quot;Edit&quot; to add a description.</i>
              </Typography>
            )}
          </Box>
        </Card>

        <CodeEditorDialog
          overlay={descriptionOverlay}
          title="Description"
          value={descriptionValue || ''}
          setValue={setDescriptionValue}
          wrapEnabledInitially
          characterLimit={8000}
          onSave={(updatedDescription) => {
            updateWorkflowState({
              ...workflowState,
              description: updatedDescription,
            })
            descriptionOverlay.close()
          }}
          description={
            <Typography variant="body2" color="text.secondary">
              This description will be available in various places throughout
              the app. Workflow state descriptions accept{' '}
              <Link
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
                rel="noreferrer"
              >
                markdown formatting
              </Link>
              .
            </Typography>
          }
          mode="markdown"
        />
      </Stack>

      <WorkflowStateRelevantFields
        excludedFieldsIds={workflowState.excluded_project_grid_fields_ids || []}
        detailQueryKey={detailQueryKey}
        workflowState={workflowState}
      />

      <DeleteWorkflowStateDialog
        overlay={deleteOverlay}
        workflowState={workflowState}
        onDelete={handleDeleteWorkflowState}
      />
      <AddEditWorkflowStateDialog
        overlay={editOverlay}
        workflowId={workflowState.workflow_id}
        workflowState={workflowState}
      />
    </Stack>
  )
}
