import { createPortal } from 'react-dom'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { Link, LinkProps, Typography, useTheme } from '@mui/material'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import { useBreadcrumbPortalContext } from './BreadcrumbPortalProvider'

function LinkRouter(props: RouterLinkProps & LinkProps) {
  return <Link {...props} component={RouterLink} />
}

type BreadcrumbProps = {
  label: string
  url?: string
  includeQueryParams?: boolean
}

export default function Breadcrumb({
  label,
  url,
  includeQueryParams,
}: BreadcrumbProps) {
  const [portalNode] = useBreadcrumbPortalContext()
  const { appendCurrentParamsToUrl } = useLocationHelpers()
  const theme = useTheme()
  return portalNode
    ? createPortal(
        <Typography
          variant="body2"
          noWrap
          color="text.secondary"
          sx={{
            '&::before': {
              content: "''",
              display: 'inline-block',
              transform: 'rotate(15deg)',
              borderRight: '1px solid currentColor',
              height: '0.9em',
              margin: '0 8px -0.1em',
              opacity: 0.65,
            },
            '&:last-child': {
              opacity: 0.75,
            },
          }}
        >
          <span style={{ opacity: url ? 1 : 0.75 }}>
            {url ? (
              <LinkRouter
                underline="hover"
                color={theme.palette.text.secondary}
                to={includeQueryParams ? appendCurrentParamsToUrl(url) : url}
              >
                {label}
              </LinkRouter>
            ) : (
              label
            )}
          </span>
        </Typography>,
        portalNode,
      )
    : null
}
