import { useState } from 'react'
import throttle from 'lodash.throttle'
import { MRT_ColumnDef } from 'material-react-table'
import { LoadingButton } from '@mui/lab'
import { TaskAlt } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { Document } from '@/types/documents'
import { DocumentStatus } from '@/types/document-workflow-states'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetDocumentWorkflowStates } from '@/service-library/hooks/document-workflow-states'
import { useDeleteDocuments } from '@/service-library/hooks/documents'
import queryKeys from '@/service-library/query-keys'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'

type ProcessingDocumentsDialogProps = {
  count: number
  overlay: OverlayState
  onCancel: () => void
}

type ProcessingDocument = Partial<Document> & {
  status: DocumentStatus
  id: string
}

const statusMapping: Record<string, string> = {
  created: 'Preparing',
  uploaded: 'Running OCR',
  ocr_complete: 'Processing',
  processing: 'Processing',
  processed: 'Processing',
}

const columns: MRT_ColumnDef<ProcessingDocument>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    id: 'submitter',
    header: 'Submitter',
    accessorFn: (originalRow) => originalRow.created_by_user?.name || '',
  },
  {
    header: 'Status',
    accessorFn: (row) => statusMapping[row.status] || 'Processing',
  },
  {
    header: 'Upload Date',
    accessorKey: 'created_at',
    Cell: ({ row }) => prettifyDate(row.original.created_at as string),
  },
]

export default function ProcessingDocumentsDialog({
  count,
  overlay,
  onCancel,
}: ProcessingDocumentsDialogProps) {
  const { getWorkflowStateByCode } = useWorkflowsContext()
  const processingWorkflowState = getWorkflowStateByCode('basic', 'processing')

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({})
  const {
    documentWorkflowStates = [],
    isLoading,
    refetch,
  } = useGetDocumentWorkflowStates({
    filters: {
      limit: '1000',
      workflow_state_id__in: processingWorkflowState?.id,
      'status__in!': 'error',
      fields__include: 'document',
      document__fields__only: 'created_at,id,name,created_by_user',
    },
    enabled: !!processingWorkflowState && overlay.isOpen, // Only fetch the documents once the overlay is opened
  })
  const { deleteDocuments, isLoading: isDeleting } = useDeleteDocuments({
    sideEffectQueryKeys: [queryKeys.documentWorkflowStates.details()], //count
    onSettled: () => {
      setSelectedRows({})
      refetch()
      onCancel()
    },
  })

  const throttledRefetch = throttle(refetch, 1000)

  const handleCancel = () => {
    const documentIds = Object.keys(selectedRows)
    deleteDocuments(documentIds)
  }

  const documents = [
    ...documentWorkflowStates.map(({ document_id, document, status }) => ({
      ...document,
      id: document_id,
      status,
    })),
  ]

  useNotifications({
    keys: [
      'document_workflow_state_status_changed',
      'document_workflow_state_changed',
    ],
    callback: () => overlay.isOpen && throttledRefetch(),
  })

  const table = useZerapixTable<ProcessingDocument>({
    data: documents,
    columns,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 40,
      },
    },
    enableTopToolbar: false,
    enableRowActions: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setSelectedRows,
    getRowId: (row) => row.id,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <CopyIDButton asIconButton stringToCopy={row.id} size="small" />
    ),
    state: {
      rowSelection: selectedRows,
      showSkeletons: isLoading,
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: isLoading ? 'default' : 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    localization: {
      toggleSelectRow: '',
    },
  })

  return (
    <Dialog
      title={`Processing Documents (${documents.length || count})`}
      {...overlay}
      maxWidth="md"
    >
      <DialogContent>
        {!isLoading && documents.length === 0 ? (
          <Stack spacing={2} sx={{ my: 2 }} alignItems="center">
            <TaskAlt sx={{ fontSize: 120 }} color="success" />
            <Typography>All documents have been processed!</Typography>
          </Stack>
        ) : (
          <ZerapixTable<ProcessingDocument> table={table} />
        )}
      </DialogContent>

      <DialogFooter>
        <Button
          variant="text"
          onClick={() => {
            overlay.close()
          }}
        >
          Close
        </Button>
        <LoadingButton
          color="error"
          variant="text"
          disabled={Object.keys(selectedRows).length === 0 || !documents.length}
          loading={isDeleting}
          onClick={handleCancel}
        >
          Cancel Processing
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
