import { useSearchParams } from 'react-router-dom'

type UseListSearchParamOptions = {
  // The name of the search param you want to use
  param: string

  // If you want to set a default value, pass it in here
  defaultValue?: string

  // If you want to clear other search params when this one changes, pass them in here
  fieldsToClearOnChange?: string[]
}

export default function useStatefulSearchParam({
  param,
  defaultValue,
  fieldsToClearOnChange = [],
}: UseListSearchParamOptions) {
  const [searchParams, setSearchParams] = useSearchParams()
  const value = searchParams.get(param) ?? defaultValue

  const toggleValue = (newValue: string) => {
    const allCurrentSearchParams = Object.fromEntries(searchParams.entries())
    fieldsToClearOnChange.forEach((field) => {
      delete allCurrentSearchParams[field]
    })
    if (newValue) {
      setSearchParams(
        { ...allCurrentSearchParams, [param]: newValue },
        { replace: true },
      )
    } else {
      setSearchParams(
        { ...allCurrentSearchParams, [param]: 'none' },
        { replace: true },
      )
    }
  }

  const resetValue = () => {
    const allCurrentSearchParams = Object.fromEntries(searchParams.entries())
    delete allCurrentSearchParams[param]
    setSearchParams(allCurrentSearchParams, { replace: true })
  }

  return {
    value,
    toggleValue,
    resetValue,
    isInUrl: searchParams.has(param),
  }
}
