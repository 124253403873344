import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Document } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import { useBulkSplitCreateDocuments } from '@/service-library/hooks/break-up-multiple-page-document'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { useAuthentication } from '@/components/auth/AuthProvider'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import ImageCard from './ImageCard'

type SplitDocumentDialogProps = {
  document: Document
  overlay: OverlayState
  onDelete: () => void
}

export const TILE_WIDTH = 240

function getPagesFromSplitPoints(
  splitPoints: number[],
  totalPoints: number,
  deletedPages: number[],
) {
  const newDocumentsPages = []
  let startPage = 1
  splitPoints.forEach((point) => {
    const pages = Array.from(
      { length: point - startPage + 1 },
      (_, index) => startPage + index,
    )

    const filteredPages = pages.filter((page) => !deletedPages.includes(page))
    if (filteredPages.length) newDocumentsPages.push(filteredPages)
    startPage = point + 1
  })

  if (startPage <= totalPoints) {
    const lastDocumentPages = Array.from(
      { length: totalPoints - startPage + 1 },
      (_, index) => startPage + index,
    )

    const filteredPages = lastDocumentPages.filter(
      (page) => !deletedPages.includes(page),
    )
    if (filteredPages.length) newDocumentsPages.push(filteredPages)
  }

  return newDocumentsPages
}

function SplitUnderline({
  splitPoint,
  distanceToNextSplitPoint,
  pointIsSelected,
}: {
  splitPoint: number
  distanceToNextSplitPoint: number
  pointIsSelected: boolean
}) {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 8,
        left: splitPoint * TILE_WIDTH + splitPoint * 20,
        width:
          distanceToNextSplitPoint * TILE_WIDTH +
          distanceToNextSplitPoint * 20 -
          20,
        height: 8,
        transition: 'all 0.1s ease-in-out',
        border: pointIsSelected ? '1px solid' : 0,
        borderTop: 'none',
      }}
    />
  )
}

export default function SplitDocumentDialog({
  document,
  overlay,
  onDelete,
}: SplitDocumentDialogProps) {
  const theme = useTheme()
  const { user } = useAuthentication()

  const [splitPoints, setSplitPoints] = useState<number[]>([])
  const [pagesToDelete, setPagesToDelete] = useState<number[]>([])
  const [deleteOriginal, setDeleteOriginal] = useState(true)

  const pages = document.document_pages

  const resetStates = () => {
    setSplitPoints([])
    setDeleteOriginal(true)
    setPagesToDelete([])
  }

  const { bulkSplitCreateDocuments, isLoading } = useBulkSplitCreateDocuments({
    id: document.id,
    filters: {
      keep_original_doc: deleteOriginal ? 'false' : 'true',
    },
    onError: () => {
      showErrorSnackbar(
        'Some documents may have failed to create. Please try again later.',
        { autoHideDuration: 3000 },
      )
    },
    onSuccess: () => {
      if (deleteOriginal) {
        onDelete()
      }
    },
    onSettled: () => {
      resetStates()
    },
  })

  const onClose = () => {
    overlay.close()
    resetStates()
  }

  const createDocuments = () => {
    overlay.close()
    showInfoSnackbar('Splitting Document')

    const documentNameWithoutExtension =
      document.name.slice(-4).toLowerCase() === '.pdf'
        ? document.name.slice(0, -4)
        : document.name
    const newDocumentsPages = getPagesFromSplitPoints(
      splitPoints,
      pages.length,
      pagesToDelete,
    )

    const newDocuments = newDocumentsPages.map((pages, index) => ({
      id: generateUuid(),
      name: `${documentNameWithoutExtension} (split-${index + 1}).pdf`,
      owner_org_id: document.owner_org_id,
      project_id: document.project_id,
      submitter: user?.name,
      submitter_email: user?.email,
      pages,
    }))

    bulkSplitCreateDocuments(newDocuments)
  }

  return (
    <Dialog
      title="Split Document"
      maxWidth="xl"
      fullWidth={false}
      {...overlay}
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            Click between the page images below to choose where to split the
            document. Each section will become a new document.
          </Typography>

          <Stack
            direction="row"
            sx={{
              position: 'relative',
              overflowX: 'auto',
              overflowY: 'visible',
              pb: 3.5,
            }}
          >
            {pages.map((page, index) => {
              const splitPoint = index + 1
              const splitPointIndex = splitPoints.findIndex(
                (point) => point === splitPoint,
              )

              const pointIsSelected = splitPointIndex !== -1

              const distanceToNextSplitPoint = pointIsSelected
                ? (splitPoints[splitPointIndex + 1] || pages.length) -
                  splitPoints[splitPointIndex]
                : 0

              // Get width based on larger page dimension
              return (
                <Stack key={page.id} direction="row">
                  <Badge
                    key={page.id}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={page.page_number}
                    color="primary"
                    component="div"
                    max={10000} // Just in case we ever have a document that has more than 99 (default) pages
                    slotProps={{
                      badge: () => ({
                        sx: { transform: 'scale(1) translate(-20%, -20%)' }, // Override default badge transform position
                      }),
                    }}
                  >
                    <ImageCard
                      page={page}
                      pagesToDelete={pagesToDelete}
                      setPagesToDelete={setPagesToDelete}
                    />
                  </Badge>
                  {index < pages.length - 1 && (
                    <ButtonBase
                      sx={{
                        width: 20,
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'divider',
                        },
                      }}
                      onClick={() => {
                        if (!pointIsSelected) {
                          setSplitPoints(
                            [...splitPoints, splitPoint].toSorted(
                              (a, b) => a - b,
                            ),
                          )
                        } else {
                          setSplitPoints(
                            splitPoints.filter((p) => p !== splitPoint),
                          )
                        }
                      }}
                    >
                      <Box
                        sx={{
                          width: 4,
                          height: '100%',
                          borderRadius: 4,
                          transition: 'all 0.1s ease-in-out',
                          background: pointIsSelected
                            ? theme.palette.secondary.main
                            : undefined,
                        }}
                      />
                    </ButtonBase>
                  )}
                  {pointIsSelected && (
                    <SplitUnderline
                      splitPoint={splitPoint}
                      distanceToNextSplitPoint={distanceToNextSplitPoint}
                      pointIsSelected={pointIsSelected}
                    />
                  )}
                </Stack>
              )
            })}
            {splitPoints.length > 0 && (
              <SplitUnderline
                splitPoint={0}
                distanceToNextSplitPoint={splitPoints[0]}
                pointIsSelected
              />
            )}
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ py: 2 }}>
          <Button
            variant="text"
            onClick={() => {
              setSplitPoints(pages.map((_, index) => index + 1).slice(0, -1))
            }}
          >
            Split All Pages
          </Button>
          <Button
            disabled={!splitPoints.length}
            variant="text"
            onClick={() => {
              setSplitPoints([])
            }}
          >
            Clear All Split Points
          </Button>
        </Stack>
        <FormControlLabel
          label="Delete Original Document"
          control={
            <Checkbox
              color={deleteOriginal ? 'error' : 'neutral'}
              sx={{ padding: 0 }}
              checked={deleteOriginal}
              onChange={(event, checked) => setDeleteOriginal(checked)}
            />
          }
          componentsProps={{
            typography: { color: deleteOriginal ? 'error' : 'neutral' },
          }}
          sx={{ mx: 0, columnGap: 1, width: 'fit-content' }}
        />
      </DialogContent>
      <DialogFooter>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <LoadingButton
          disabled={!splitPoints.length}
          variant="contained"
          onClick={createDocuments}
          loading={isLoading} // Just in case they reopen the dialog when the previous split is still in progress.
        >
          Confirm
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
