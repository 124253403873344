import { createContext, ReactNode, useContext, useEffect } from 'react'
import { WorkflowState } from '@/types/workflows'
import useStatefulSearchParam from '@/hooks/useStatefulSearchParam'
import { useWorkflowStatesContext } from '@/components/workflow-states-provider/WorkflowStatesProvider'
import { useLocation } from 'react-router-dom'

type SelectedWorkflowStateContextValue = {
  selectedWorkflowState: WorkflowState
  setWorkflowState: (workflowStateId: string) => void
}

const SelectedWorkflowStateContext =
  createContext<SelectedWorkflowStateContextValue>(
    {} as SelectedWorkflowStateContextValue,
  )

export const useSelectedWorkflowStateContext = () =>
  useContext(SelectedWorkflowStateContext)

type SelectedWorkflowStateProviderProps = {
  children: ReactNode
}

export default function SelectedWorkflowStateProvider({
  children,
}: SelectedWorkflowStateProviderProps) {
  const { workflowStates, isLoading } = useWorkflowStatesContext()
  const { pathname } = useLocation()

  // Default to the first state that is not processing
  const defaultState = workflowStates.find(
    ({ code }) => code !== 'processing', // We skip processing
  ) as WorkflowState

  const {
    value: workflowStateIdInUrl,
    toggleValue: setWorkflowStateIdInUrl,
    resetValue,
    isInUrl,
  } = useStatefulSearchParam({
    param: 'workflow_state',
    defaultValue: defaultState?.id, // defaultState might be undefined if there are no states
  })

  const selectedWorkflowState = workflowStates.find(
    ({ id }) => id === workflowStateIdInUrl,
  )

  // If we have a selected workflow state, but its in a different workflow, switch to that workflow.
  useEffect(() => {
    if (workflowStates.length && !selectedWorkflowState && defaultState?.id) {
      setWorkflowStateIdInUrl(defaultState?.id)
    }
  }, [
    defaultState?.id,
    selectedWorkflowState,
    setWorkflowStateIdInUrl,
    workflowStates.length,
  ])

  useEffect(() => {
    if (isInUrl && pathname.includes('settings')) {
      resetValue()
    }
  })

  return (
    <SelectedWorkflowStateContext.Provider
      value={{
        selectedWorkflowState: selectedWorkflowState as WorkflowState,
        setWorkflowState: setWorkflowStateIdInUrl,
      }}
    >
      {!isLoading && children}
    </SelectedWorkflowStateContext.Provider>
  )
}
