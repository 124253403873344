import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import throttle from 'lodash.throttle'
import {
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table'
import { Description } from '@mui/icons-material'
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { DocumentRow } from '@/types/documents'
import { ProjectGridField } from '@/types/fields'
import { ProjectGrid } from '@/types/projects'
import { Workflow, WorkflowState } from '@/types/workflows'
import useColumnFilters from '@/hooks/useColumnFilters'
import useColumnSortingLogic from '@/hooks/useColumnSortingLogic'
import useContextMenuPosition from '@/hooks/useContextMenuPosition'
import useDocumentDashboardIds from '@/hooks/useDocumentDashboardIds'
import useDocumentGrid from '@/hooks/useDocumentGrid'
import useFetchMoreOnBottomReached from '@/hooks/useFetchMoreOnBottomReached'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useLocalStorage from '@/hooks/useLocalStorage'
import useStatefulSearchParam from '@/hooks/useStatefulSearchParam'
import useOverlay from '@/hooks/useOverlay'
import useValidationNavList from '@/hooks/useValidationNavList'
import { useGetDocumentWorkflowStates } from '@/service-library/hooks/document-workflow-states'
import queryKeys from '@/service-library/query-keys'
import { getBaseGrid, sortBySortOrder } from '@/utils/field-utils'
import DocumentDialog from '@/components/document-dialog/DocumentDialog'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import {
  WebsocketData,
  useNotifications,
} from '@/components/notifications/NotificationProvider'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import { useColumnOrderContext } from '@/components/workflows/ColumnOrderProvider'
import { useColumnSortingContext } from '@/components/workflows/ColumnSortingProvider'
import { useColumnVisibilityContext } from '@/components/workflows/ColumnVisibilityProvider'
import DocumentGridActionButtons from '@/components/workflows/DocumentGridActionButtons'
import DocumentGridActionsMenu from '@/components/workflows/DocumentGridActionsMenu'
import DocumentGridRowMenu from '@/components/workflows/DocumentGridRowMenu'
import { useSelectedGridsContext } from '@/components/workflows/SelectedGridsProvider'
import { useViewsContext } from '@/components/workflows/ViewsProvider'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import { useDocumentCount } from '@/components/document-count-provider/DocumentCountProvider'
import WorkflowStateDescriptionDialog from '@/components/workflow-state-page/WorkflowStateDescriptionDialog'
import ValidationOverlay from '@/components/validation-overlay/ValidationOverlay'
import InFlightDocumentsDialog from '@/components/project-dashboard/InFlightDocumentsDialog'
import DragonFillProvider, { useDragonFillContext } from './DragonFillProvider'
import useDocumentColumns from './useDocumentColumns'
import useDocumentRowsColumns from './useDocumentRowsColumns'
import SelectAllBanner from './SelectAllBanner'

export type BaseDashboardTableProps = {
  isFullscreen: boolean
  state: WorkflowState
  workflow: Workflow
  setIsFullscreen: Dispatch<SetStateAction<boolean>>
}

export type DashboardTableProps = BaseDashboardTableProps & {
  columns: MRT_ColumnDef<Required<DocumentRow>>[]
  queryBy: 'rows' | 'documents'
}

function DashboardTable({
  isFullscreen,
  state,
  workflow,
  columns,
  queryBy,
  setIsFullscreen,
}: DashboardTableProps) {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const documentIdState = useStatefulSearchParam({
    param: 'document_id',
  })
  const { project } = useProjectContext()
  const baseGrid = getBaseGrid(project.project_grids as ProjectGrid[])

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({})
  const [selectedAllLoaded, setSelectedAllLoaded] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)
  const [useOnlySelected, setUseOnlySelected] = useState(false)

  const {
    columnOrder,
    draggingColumn,
    handleColumnOrderChange,
    setColumnOrder,
    setDraggingColumn,
  } = useColumnOrderContext()
  const { sorting, handleSortingChange, resetColumnSorting, setSorting } =
    useColumnSortingContext()
  const {
    columnVisibility,
    handleColumnVisibilityChange,
    resetColumnVisibility,
  } = useColumnVisibilityContext()
  const [columnSizing, setColumnSizing] = useLocalStorage(
    `columns-sizing-${state.id}`,
    {},
  )

  const documentationOverlay = useOverlay()
  const validationOverlay = useOverlay()

  function openValidationOverlay(documentId: string) {
    documentIdState.toggleValue(documentId)
    validationOverlay.open()
  }

  const { documentFilters, includeFilter } = useDocumentFiltersContext()
  const { selectedGrids } = useSelectedGridsContext()
  const { selectedView, isInitialLoading: viewsIsLoading } = useViewsContext()

  const [highlightedValues] = useDragonFillContext()

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const [dialogDocumentId, setDialogDocumentId] = useState<string | null>(null)
  const documentDialogOverlay = useOverlay()

  const contextMenuOverlay = useOverlay()
  const contextMenu = useContextMenuPosition()

  const fields = useMemo<ProjectGridField[]>(
    () =>
      baseGrid?.id
        ? Object.values(selectedGrids).flatMap((grid) =>
            //TODO: Remove sorting for when the backend returns data sorted
            sortBySortOrder(grid.project_grid_fields),
          )
        : [],
    [baseGrid?.id, selectedGrids],
  )

  const {
    columnFilters,
    columnFilterFns,
    filteringLogic,
    setColumnFilters,
    setColumnFilterFns,
  } = useColumnFilters({ fields, selectedGrids, baseGridId: baseGrid?.id })

  const sortingLogic = useColumnSortingLogic({
    fields,
    baseGridId: baseGrid?.id,
    selectedGrids,
  })

  const queryGrids = useMemo(() => {
    const tempQueryGrids = Object.keys(selectedGrids)
    if (
      baseGrid?.id &&
      (tempQueryGrids.length === 0 ||
        (!tempQueryGrids.includes(baseGrid.id) &&
          !filteringLogic.fieldType &&
          !sortingLogic.fieldType))
    ) {
      tempQueryGrids.push(baseGrid.id)
    }
    return tempQueryGrids
  }, [
    baseGrid?.id,
    filteringLogic.fieldType,
    selectedGrids,
    sortingLogic.fieldType,
  ])

  const { countByWorkflowStateId, exportingCount, processingCount } =
    useDocumentCount()
  const idleDocumentCount = countByWorkflowStateId[state.id]
    ? countByWorkflowStateId[state.id].total -
      countByWorkflowStateId[state.id].in_flight
    : 0

  const commonOptions = {
    projectId: project.id,
    workflowStateId: state.id,
    flagCodes: includeFilter.flags ? documentFilters?.flagCodes : undefined,
    filtering: filteringLogic.fieldType ? filteringLogic.filtering : undefined,
    ordering: sortingLogic.fieldType ? sortingLogic.columnIds : undefined,
    orgIds: includeFilter.orgs && documentFilters?.orgIds,
    teamIds: includeFilter.teams ? documentFilters?.teamIds : undefined,
  }
  const {
    documentsCount,
    rows,
    rowsUniqueDocIds,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isRefetching,
    isInitialLoading: rowsIsLoading,
    refetch,
  } = useDocumentGrid({
    projectGridIds: queryGrids,
    queryBy,
    ...commonOptions,
    // As a backup to the web sockets, we want to refetch the data every minute while something is processing or exporting
    refetchInterval:
      processingCount > 0 || exportingCount > 0 ? 60_000 : undefined,
  })

  const { documents: documentsIds, isFetchingAll } = useDocumentDashboardIds({
    ...commonOptions,
    enabled: selectedAll,
  })

  const {
    documentWorkflowStates = [],
    refetch: refetchDocumentWorkflowStates,
  } = useGetDocumentWorkflowStates({
    filters: {
      limit: '1000',
      workflow_state_id: state.id,
      status__in: 'error,exporting',
    },
    enabled: state.code === 'ready_for_export',
  })

  const throttledRefetchDocumentWorkflowStates = throttle(
    refetchDocumentWorkflowStates,
    1000,
  )

  const updateStateTableCallback = useCallback(
    ({ action }: WebsocketData) => {
      if (action === 'export_complete') {
        queryClient.invalidateQueries({
          queryKey: ['export_files', { projectId: project.id }],
        })
        refetch()
      } else if (
        // When documents move from one state to another
        action === 'document_workflow_state_changed'
      ) {
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentRows.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documents.all },
          { cancelRefetch: false },
        )
        state.code === 'ready_for_export' &&
          throttledRefetchDocumentWorkflowStates()
      } else if (
        // Document started exporting, has a failure, etc.
        action === 'document_workflow_state_status_changed' &&
        state.code === 'ready_for_export'
      ) {
        throttledRefetchDocumentWorkflowStates()
      }
    },
    [
      project.id,
      queryClient,
      refetch,
      state.code,
      throttledRefetchDocumentWorkflowStates,
    ],
  )

  useNotifications({
    keys: [state.id],
    callback: (action) => {
      updateStateTableCallback(action)
    },
  })

  const columnsIds = useMemo(() => {
    const customColumnsIds = columns.map(({ id }) => id as string)
    const rowOrBaseColIndex = customColumnsIds.findIndex(
      (id) => id === 'rowOrBaseId',
    )
    if (rowOrBaseColIndex === -1) {
      customColumnsIds.splice(1, 0, 'rowOrBaseId')
    }
    return ['mrt-row-select', ...customColumnsIds]
  }, [columns])

  const selectedLoadedDocumentIds = [
    ...new Set(Object.keys(selectedRows).map((rowId) => rowId.split('_')[0])),
  ]

  const selectedDocumentIds =
    selectedAll && !isFetchingAll
      ? documentsIds.map(({ id }) => id)
      : selectedLoadedDocumentIds
  const columnsLengthRef = useRef({ old: columnsIds.length })
  const gridsRef = useRef({ old: project.project_grids })
  const viewRef = useRef({ old: selectedView.id })

  const validationNavListQuery = useValidationNavList({
    currentDocumentId: documentIdState.value,
    documentIds: useOnlySelected ? selectedDocumentIds : rowsUniqueDocIds,
    setDocumentId: documentIdState.toggleValue,
    rowsCount: rows.length,
    fetchNextPage:
      !useOnlySelected && hasNextPage && !isFetching && validationOverlay.isOpen
        ? fetchNextPage
        : undefined,
  })

  const { fetchMoreOnBottomReached } = useFetchMoreOnBottomReached({
    isFetching,
    hasNextPage,
    tableContainerRef,
    fetchNextPage,
    offset: 2500,
  })

  const updateColumnsForViews = useCallback(
    (isViewChange = false) => {
      setColumnOrder((prev = []) => {
        const newOrder = isViewChange
          ? selectedView.state.column_order
          : [...prev]
        const newColumns = columnsIds.filter(
          (columnId) => !newOrder.includes(columnId),
        )
        return [...newOrder, ...newColumns]
      })
    },
    [columnsIds, selectedView.state.column_order, setColumnOrder],
  )

  const getThemeColor = useGetThemeColor()
  const color = getThemeColor(state.color)

  function getIsExporting(documentId: string) {
    if (!document) return false
    const documentWorkflowState = documentWorkflowStates.find(
      ({ document_id }) => documentId === document_id,
    )
    return documentWorkflowState?.status === 'exporting'
  }

  const resetSelection = useCallback(() => {
    setSelectedRows({})
    setSelectedAll(false)
    setSelectedAllLoaded(false)
  }, [])

  // Column visibility will affect sorting and filtering, so we need a way to check that they are really changing
  const sortingRef = useRef(sorting)
  const columnFiltersRef = useRef(columnFilters)

  useEffect(() => {
    if (JSON.stringify(sorting) !== JSON.stringify(sortingRef.current)) {
      resetSelection()
      sortingRef.current = sorting
    }
  }, [resetSelection, sorting])

  useEffect(() => {
    if (
      JSON.stringify(columnFilters) !== JSON.stringify(columnFiltersRef.current)
    ) {
      resetSelection()
      columnFiltersRef.current = columnFilters
    }
  }, [columnFilters, resetSelection])

  useEffect(() => {
    resetSelection()
  }, [
    state.id,
    documentFilters?.flagCodes,
    documentFilters?.orgIds,
    documentFilters?.teamIds,
    resetSelection,
  ])

  // This is used for when selected rows get moved or deleted on a different page or tab, so
  // selected rows can get updated with 'actual' rows
  useEffect(() => {
    if (!Object.keys(selectedRows).length) return

    const rowsIds = rows.map(({ document_id, id, project_grid_id }) =>
      queryBy === 'documents' || project_grid_id === baseGrid?.id
        ? document_id
        : `${document_id}_${id}`,
    )
    const unselectedIds = Object.keys(selectedRows).filter(
      (id) => !rowsIds.includes(id),
    )

    if (unselectedIds.length) {
      setSelectedAll(false)
      setSelectedAllLoaded(false)
      setSelectedRows((prev) => {
        if (!Object.keys(prev).length) return prev
        const updatedRows = { ...prev }
        unselectedIds.forEach((id) => {
          delete updatedRows[id]
        })
        return updatedRows
      })
    } else if (
      // This is for when user keeps scrolling to fetch more data and had previously selected "Select All"
      selectedAll &&
      rowsIds.length > Object.keys(selectedRows).length
    ) {
      setSelectedRows(rowsIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}))
    }
  }, [baseGrid?.id, queryBy, rows, selectedAll, selectedRows])

  useEffect(() => {
    if (viewRef.current.old !== selectedView.id) {
      resetSelection()
      if (selectedView.id === 'default') {
        setColumnOrder([])
      } else {
        updateColumnsForViews(true)
      }
      resetColumnSorting()
      resetColumnVisibility()
      viewRef.current.old = selectedView.id
    }
  }, [
    resetColumnSorting,
    resetColumnVisibility,
    resetSelection,
    selectedView.id,
    selectedView.state.column_visibility,
    selectedView.state.sorting,
    setColumnOrder,
    updateColumnsForViews,
  ])

  useEffect(() => {
    const previousLength = columnsLengthRef.current.old
    const currentLength = columnsIds.length

    const previousGrids = gridsRef.current.old
    if (project.project_grids !== previousGrids) {
      if (selectedView.id === 'default') {
        setColumnOrder(columnsIds)
      } else {
        updateColumnsForViews()
      }
      gridsRef.current.old = project.project_grids
      columnsLengthRef.current.old = currentLength
    } else if (previousLength < currentLength) {
      if (selectedView.id === 'default') {
        setColumnOrder((prev = []) =>
          prev.length
            ? [...prev, ...columnsIds.slice(previousLength)]
            : columnsIds,
        )
      } else {
        updateColumnsForViews()
      }
      columnsLengthRef.current.old = currentLength
    }
  }, [
    columnsIds,
    project.project_grids,
    selectedView.id,
    setColumnOrder,
    updateColumnsForViews,
  ])

  useEffect(() => {
    const visibleColumns = Object.entries(columnVisibility)
      .filter(([, visible]) => visible)
      .map(([id]) => id)
    // We remove field from sorting if user hides it, except for the ones in the selected view
    if (sorting.length > 0) {
      const viewSorting = (selectedView.state.sorting || []).map(({ id }) => id)
      setSorting((prevSorting) =>
        prevSorting.filter(
          ({ id }) => viewSorting.includes(id) || visibleColumns.includes(id),
        ),
      )
    }
    // We remove field from filter if user hides it
    if (columnFilters.length > 0) {
      setColumnFilters((prevColumnFilters) =>
        prevColumnFilters.filter(({ id }) => visibleColumns.includes(id)),
      )
    }
  }, [
    columnFilters.length,
    columnVisibility,
    selectedView.state.sorting,
    setColumnFilters,
    setSorting,
    sorting.length,
  ])

  const showSelectAllBanner =
    selectedAll ||
    (selectedAllLoaded && documentsCount > selectedLoadedDocumentIds.length)

  const allSelectedBannerHeight = showSelectAllBanner ? 45 : 0

  useEffect(() => {
    if (!Object.keys(selectedRows).length) {
      selectedAll && setSelectedAll(false)
      selectedAllLoaded && setSelectedAllLoaded(false)
    }
  }, [rows.length, selectedAll, selectedAllLoaded, selectedRows])

  const table = useZerapixTable<Required<DocumentRow>>({
    columns,
    data: rows as Required<DocumentRow>[],
    displayColumnDefOptions: {
      'mrt-row-select': {
        muiTableBodyCellProps: rowsIsLoading
          ? undefined
          : {
              sx: {
                '&::before': {
                  content: '""',
                  transition: 'all 0.1s',
                  width: 4,
                  background: color,
                  display: 'inline-block',
                  height: '90%',
                  position: 'absolute',
                  top: '5%',
                  left: 0,
                  zIndex: 2,
                },
              },
            },
      },
    },
    enableColumnDragging: true,
    enableColumnFilters: true,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableColumnActions: true,
    enableDensityToggle: false,
    enableFilters: true,
    enableFilterMatchHighlighting: false, // Disabling to be consistent since highlight doesn't work for the fields values
    enableHiding: false,
    enableRowSelection: (row) => {
      return (
        queryBy === 'documents' ||
        row.original.project_grid_id === baseGrid?.id ||
        filteringLogic.fieldType === 'table' ||
        sortingLogic.fieldType === 'table'
      )
    },
    enableMultiRowSelection: true,
    enableRowVirtualization: true,
    enableSorting: true,
    enableToolbarInternalActions: true,
    manualFiltering: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnSizingChange: setColumnSizing,
    onDraggingColumnChange: setDraggingColumn,
    onSortingChange: handleSortingChange,
    onIsFullScreenChange: setIsFullscreen,
    rowVirtualizerOptions: { overscan: 300 },
    muiSelectAllCheckboxProps: {
      // We don't use onChange because that would override onRowSelectionChange
      onClick: (event) => {
        if (queryBy === 'rows') return
        const isChecked = (event.target as HTMLInputElement).checked
        setSelectedAllLoaded(isChecked)
        if (!isChecked && selectedAll) {
          setSelectedAll(false)
        }
      },
    },
    muiSelectCheckboxProps: ({ row }) => {
      return {
        // We don't use onChange because that would override onRowSelectionChange
        onClick: (event) => {
          if (queryBy === 'rows') return
          const isChecked = (event.target as HTMLInputElement).checked
          if (!isChecked && selectedAllLoaded) {
            setSelectedAllLoaded(false)
            selectedAll && setSelectedAll(false)
          }
        },
        sx: {
          visibility:
            queryBy === 'documents' ||
            row.original.project_grid_id === baseGrid?.id ||
            filteringLogic.fieldType === 'table' ||
            sortingLogic.fieldType === 'table'
              ? 'initial'
              : 'hidden',
          p: 2.25,
        },
      }
    },
    onRowSelectionChange: setSelectedRows,
    getRowId: (originalRow) => {
      return queryBy === 'documents' ||
        originalRow.project_grid_id === baseGrid?.id
        ? originalRow.document_id
        : originalRow.document_id
        ? `${originalRow.document_id}_${originalRow.id}`
        : originalRow.id
    },
    muiTablePaperProps: () => ({
      style: {
        zIndex: isFullscreen ? theme.zIndex.drawer + 100 : undefined, // using sx won't work https://www.material-react-table.com/docs/guides/full-screen-toggle#change-z-index-of-full-screen-table
      },
    }),
    muiTableHeadCellProps: () => {
      return {
        sx: {
          // Cut the header off with an ellipsis if it is too long
          '& .Mui-TableHeadCell-Content-Wrapper': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      }
    },
    muiTableBodyCellProps: ({ column }) => {
      const meta = column.columnDef.meta || {}

      const {
        // @ts-expect-error -- Not worth typing
        columnSubGroupPosition,
        // columnType, TODO: We used to use this to add a specific style for the data lineage columns. (Removing backdrop filter for not [sc-10883])
        // @ts-expect-error -- Not worth typing
        subGroupIds = [],
        // @ts-expect-error -- Not worth typing
        field,
      } = meta
      const isGroupVisible = subGroupIds.some(
        (id: string) => columnVisibility[id],
      )
      const groupBorder: Record<string, string> = {}
      if (columnSubGroupPosition === 'first') {
        groupBorder.borderLeft = `solid 2px ${theme.palette.divider}`
      } else if (isGroupVisible && columnSubGroupPosition === 'last') {
        groupBorder.borderRight = `solid 2px ${theme.palette.divider}`
      }
      return {
        sx: {
          ...groupBorder,
          py: field?.project_grid_field_type.code === 'picker' ? 0 : 1,
          overflow:
            field?.project_grid_field_type.code === 'picker'
              ? 'visible'
              : 'hidden',
        },
      }
    },
    muiTableBodyRowProps: ({ row }) => {
      const isExporting = getIsExporting(row.original.document_id)
      return {
        'data-testid': 'dg-row',
        onClick: () => {
          if (isExporting || highlightedValues.length > 0) return
          if (!rowsIsLoading) {
            openValidationOverlay(row.original.document?.id)
          }
        },
        onContextMenu: (event) => {
          event.preventDefault()
          contextMenu.handleUpdatePosition(event.clientX, event.clientY)
          setDialogDocumentId(row.original.document_id)
          contextMenuOverlay.open(event)
        },
        sx: {
          cursor: rowsIsLoading || isExporting ? 'default' : 'pointer',
          filter: isExporting ? 'brightness(0.7)' : 'initial',
        },
      }
    },
    muiTableContainerProps: {
      // @ts-expect-error -- wait for mui to update
      'data-testid': 'dg-table',
      ref: tableContainerRef, //get access to the table container element
      sx: {
        height: isFullscreen
          ? 'auto'
          : `calc(100vh - ${155 + allSelectedBannerHeight}px)`,
      },
      onScroll: (
        event, //add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    muiTableFooterProps: {
      // @ts-expect-error -- wait for mui to update
      'data-testid': 'dg-footer',
    },
    muiColumnDragHandleProps: {
      id: 'table-handle',
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          p={1}
          sx={{
            background: theme.palette.background.paper,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              sx={{ px: 1, py: 0.5, width: '100%' }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h6">
                  {/* title */}
                  {queryBy === 'rows' ? 'Document Data' : 'Documents'}{' '}
                  {queryBy === 'documents' && (
                    <Typography
                      component="span"
                      color="text.secondary"
                      variant="h6"
                    >
                      (
                      {!rowsIsLoading && documentsCount < idleDocumentCount
                        ? `${documentsCount}/${idleDocumentCount}`
                        : idleDocumentCount}
                      )
                    </Typography>
                  )}
                </Typography>
                {state?.description && (
                  <>
                    <Tooltip
                      title="View Documentation"
                      placement="right"
                      enterDelay={1000}
                    >
                      <IconButton
                        size="small"
                        className="description-button"
                        onClick={(e) => {
                          e.stopPropagation()
                          documentationOverlay.open(e)
                        }}
                      >
                        <Description fontSize="inherit" />
                      </IconButton>
                    </Tooltip>

                    <WorkflowStateDescriptionDialog
                      overlay={documentationOverlay}
                      projectId={project.id}
                      workflowState={state}
                    />
                  </>
                )}

                <InFlightDocumentsDialog
                  count={countByWorkflowStateId[state.id]?.in_flight || 0}
                  openValidationOverlay={openValidationOverlay}
                  stateId={state.id}
                />
              </Stack>

              {/* renderTableActions */}
              <DocumentGridActionButtons
                workflow={workflow}
                state={state}
                selectedDocumentIds={selectedDocumentIds}
                tableHasData={!!rows.length}
                setSelectedRows={setSelectedRows}
                onViewSelected={() => {
                  setUseOnlySelected(true)
                  openValidationOverlay(selectedDocumentIds[0])
                }}
              />
            </Stack>
            {/* renderToolbarInternalActions */}
            <Stack
              data-testid="dg-internal-actions"
              direction="row"
              alignItems="center"
              sx={{ flexShrink: 0 }}
            >
              <DocumentGridActionsMenu
                selectedDocumentIds={selectedDocumentIds}
                workflow={workflow}
                setSelectedRows={setSelectedRows}
                refetchDocuments={refetch}
              />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </Stack>
          </Stack>
          {showSelectAllBanner && (
            <SelectAllBanner
              loadedDocumentsCount={selectedLoadedDocumentIds.length}
              isFetchingDocs={isFetchingAll}
              selectedAll={selectedAll}
              setSelectedAll={setSelectedAll}
              setSelectedAllLoaded={setSelectedAllLoaded}
              setSelectedRows={setSelectedRows}
              totalDocumentsCount={documentsCount}
            />
          )}
          <LinearProgress
            sx={{
              visibility: isFetching && !isRefetching ? 'visible' : 'hidden',
            }}
          />
        </Box>
      )
    },
    state: {
      columnFilters,
      columnFilterFns,
      columnOrder: columnOrder.length ? columnOrder : columnsIds,
      columnPinning: { left: ['mrt-row-select', 'indicators'] },
      columnSizing,
      columnVisibility,
      density: 'compact',
      draggingColumn,
      isFullScreen: isFullscreen,
      rowSelection: selectedRows,
      showSkeletons: !isFullscreen && (rowsIsLoading || viewsIsLoading),
      sorting,
    },
    positionToolbarAlertBanner: 'bottom',
    onColumnVisibilityChange: handleColumnVisibilityChange,
    localization: {
      noRecordsToDisplay: 'Drag and drop files here to upload them.',
      toggleSelectRow: '',
    },
  })

  return (
    <>
      <ZerapixTable<Required<DocumentRow>> table={table} />
      <ValidationOverlay
        overlay={validationOverlay}
        documentsCount={
          queryBy === 'documents'
            ? useOnlySelected
              ? selectedDocumentIds.length
              : documentsCount
            : undefined
        }
        documentIdState={documentIdState}
        refetchTableData={refetch}
        setUseOnlySelected={setUseOnlySelected}
        {...validationNavListQuery}
      />
      {dialogDocumentId && (
        <>
          <DocumentGridRowMenu
            overlay={contextMenuOverlay}
            position={contextMenu.position}
            documentId={dialogDocumentId}
            projectId={project.id}
            onOpenDocumentDialog={() => {
              documentDialogOverlay.open()
            }}
            onDelete={() => {
              setDialogDocumentId(null)
            }}
          />

          <DocumentDialog
            documentId={dialogDocumentId}
            overlay={documentDialogOverlay}
            project={project}
            onBeforeDelete={() => {
              setDialogDocumentId(null)
            }}
          />
        </>
      )}
    </>
  )
}

const MemoizedDashboardTable = memo(DashboardTable)

export function DocumentTable(props: BaseDashboardTableProps) {
  const { state } = props

  const columns = useDocumentColumns({
    workflowState: state,
  })
  return (
    <DragonFillProvider>
      <MemoizedDashboardTable
        columns={columns}
        queryBy="documents"
        {...props}
      />
    </DragonFillProvider>
  )
}

export function DocumentRowsTable(props: BaseDashboardTableProps) {
  const { state, workflow } = props
  const columns = useDocumentRowsColumns({
    workflowState: state,
    workflow,
  })
  return (
    <DragonFillProvider>
      <MemoizedDashboardTable columns={columns} queryBy="rows" {...props} />
    </DragonFillProvider>
  )
}
