import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import debounce from 'lodash.debounce'
import {
  Alert,
  Autocomplete,
  Container,
  Link,
  ListItem,
  Paper,
  // InputAdornment,
  // Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import useOverlay from '@/hooks/useOverlay'
import {
  useGetProjectGridFields,
  useUpdateProjectGridFields,
} from '@/service-library/hooks/project-grid-fields'
import { useGetProjectModel } from '@/service-library/hooks/project-models'
import { getBaseGrid } from '@/utils/field-utils'
import { showErrorSnackbar } from '@/utils/snackbars'
import LargeHeading from '@/components/large-heading/LargeHeading'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import EditModelDialog from './EditModelDialog'
import { CATEGORY_MODEL_TYPE_ID } from './helpers'

// const FIELD_RATIO_DESCRIPTION =
//   'This determines the threshold of new occurrences for a field required for the model to auto-train. For example, if the model was last trained with 100 occurrences for a field, with a threshold of 20%, the model will auto-train after 20 new occurrences are added to the training batch for that field.'
// const CATEGORY_RATIO_DESCRIPTION =
//   'This determines the threshold of new occurrences for a category item required for the model to auto-train. For example, if the model was last trained with 100 occurrences for a category item, with a threshold of 20%, the model will auto-train after 20 new occurrences are added to the training batch for that category item.'

// const FIELD_ACCURACY_DESCRIPTION = `The model will only auto-train if the field accuracy of the field that triggers auto-training is below this threshold. For example, if a field meets the Field Occurences Ratio, but is already at or above the accuracy level configured here, then it will not trigger auto-training. This prevents unnecessary auto-training.`
// const CATEGORY_ACCURACY_DESCRIPTION = `The model will only auto-train if the field accuracy of the category item that triggers auto-training is below this threshold. For example, if a category item meets the Category Occurences Ratio, but is already at or above the accuracy level configured here, then it will not trigger auto-training. This prevents unnecessary auto-training.`

export default function ModelConfiguration() {
  const overlay = useOverlay()
  const navigate = useNavigate()
  const { workflows, selectedWorkflow } = useSelectedWorkflowContext()
  const [includedFields, setIncludedFields] = useState<ProjectGridField[]>()

  const { project } = useProjectContext()

  const { projectModel, queryKey: projectModelQueryKey } = useGetProjectModel({
    id: selectedWorkflow.project_model_id || '',
  })

  const isCategoryModel =
    projectModel?.project_model_type_id === CATEGORY_MODEL_TYPE_ID

  const isRootModel = projectModel?.parent_model_id === null

  const { projectGridFields = [], queryKey } = useGetProjectGridFields({
    filters: {
      limit: '1',
      project_id: projectModel?.project_id,
      project_model_id: projectModel?.id || '',
      fields__include: 'included_grid_fields',
    },
    enabled:
      !!projectModel &&
      isRootModel &&
      isCategoryModel &&
      projectModel.is_content_category_rows, // we only need the field for models that are related to "table" picker fields
  })

  const { updateProjectGridFields } = useUpdateProjectGridFields({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar(
        'Failed to update model settings. Please try again later.',
      )
    },
  })

  const updateProjectGridFieldsRef = useRef(updateProjectGridFields)
  const debouncedUpdateFields = useMemo(
    () => debounce(updateProjectGridFieldsRef.current, 1000),
    [],
  )

  const pickerFieldInTable = projectGridFields[0]

  const { includedFieldsOptions, parentField } = useMemo(() => {
    if (!isRootModel || projectModel?.is_content_category_whole_document)
      return { includedFieldsOptions: [], parentField: undefined }
    const baseFields =
      getBaseGrid(project.project_grids || [])?.project_grid_fields || []
    const options = baseFields.filter((field) => {
      switch (field.project_grid_field_type.code) {
        case 'bar_chart':
        case 'category':
        case 'checkbox':
        case 'grid':
          return false
        default:
          return true
      }
    })

    return {
      includedFieldsOptions: options,
      parentField: baseFields.find(
        (field) =>
          field.sub_project_grid_id === pickerFieldInTable?.project_grid_id,
      ),
    }
  }, [
    isRootModel,
    projectModel?.is_content_category_whole_document,
    pickerFieldInTable?.project_grid_id,
    project.project_grids,
  ])

  const showConfiguration = isCategoryModel && isRootModel && pickerFieldInTable
  const parentWorkflow = projectModel?.parent_model_id
    ? workflows.find(
        ({ project_model_id }) =>
          projectModel.parent_model_id === project_model_id,
      )
    : undefined

  // const [autoTrain, setAutoTrain] = useState(false)
  // const [documentThreshold, setDocumentThreshold] = useState(10)
  // const [occurrencesRatio, setOccurrencesRatio] = useState(20)
  // const [accuracyThreshold, setAccuracyThreshold] = useState(95)

  // const conditionsAreMet =
  //   documentThreshold && occurrencesRatio && accuracyThreshold

  // let borderColor = autoTrain ? 'primary.main' : ''
  // if (autoTrain && !conditionsAreMet) {
  //   borderColor = 'warning.main'
  // }

  useEffect(() => {
    if (selectedWorkflow && !selectedWorkflow?.project_model_id) {
      navigate('..', { relative: 'path' })
    }
  })

  useEffect(() => {
    pickerFieldInTable &&
      !includedFields &&
      setIncludedFields(pickerFieldInTable.included_grid_fields)
  }, [includedFields, pickerFieldInTable])

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <ProjectBreadcrumb label="Model Configuration" url="." />
      <Stack spacing={2}>
        <LargeHeading
          heading={projectModel?.name}
          subHeading="Model Configuration"
        />

        {showConfiguration && (
          <Paper
            component={Stack}
            variant="outlined"
            sx={{
              borderRadius: 2,
              p: 2,
              transition: '0.2s',
            }}
            spacing={2}
          >
            <Typography>
              This Category Model is used to predict the &quot;
              {pickerFieldInTable.name}&quot; field in the &quot;
              {parentField?.name}&quot; table. All other values in the same row
              will be used as context for prediction. <br />
              Please choose which additional fields should be used as context.
            </Typography>

            <Autocomplete
              multiple
              value={includedFields || []}
              autoHighlight
              disableCloseOnSelect
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option?.id === value.id}
              options={includedFieldsOptions}
              onChange={(_e, options) => {
                setIncludedFields(options)
                debouncedUpdateFields([
                  {
                    ...pickerFieldInTable,
                    included_grid_fields_ids: options.map(
                      (option) => option?.id,
                    ),
                    included_grid_fields: options,
                  },
                ])
              }}
              noOptionsText="No fields available."
              renderOption={(props, option) => {
                return (
                  <ListItem {...props} key={option?.id} dense>
                    {option.name}
                  </ListItem>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fields"
                  helperText="The model will need to be re-trained after these fields
                  are changed."
                />
              )}
            />
          </Paper>
        )}

        {!showConfiguration &&
          (parentWorkflow ? (
            <Alert severity="info" variant="outlined">
              This model is inheriting its configuration from{' '}
              <Link
                underline="none"
                onClick={() => {
                  // Using navigate so that the change can be kept in history
                  navigate({ search: `?workflow=${parentWorkflow.id}` })
                }}
                sx={{ cursor: 'pointer' }}
              >
                {parentWorkflow.name}
              </Link>
              .
            </Alert>
          ) : (
            <Typography variant="caption">
              This model requires no configuration.
            </Typography>
          ))}

        {/* <Paper
          component={Stack}
          variant="outlined"
          sx={{
            borderRadius: 2,
            p: 2,
            borderColor,
            transition: '0.2s',
          }}
          spacing={2}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography component="h2" variant="h6">
              Auto-Train
            </Typography>
            <Switch
              checked={autoTrain}
              onChange={() => {
                setAutoTrain((prev) => !prev)
              }}
            />
          </Stack>

          // How many documents 
          <TextField
            label="Document Threshold"
            type="number"
            helperText="Number of new documents required before auto-training the model."
            value={documentThreshold}
            disabled={!autoTrain}
            onChange={(event) => {
              setDocumentThreshold(parseInt(event.target.value))
            }}
          />

          <TextField
            label={
              isCategoryModel
                ? 'Category Occurences Ratio Percent'
                : 'Field Occurences Ratio Percent'
            }
            type="number"
            value={occurrencesRatio}
            disabled={!autoTrain}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
            helperText={
              isCategoryModel
                ? CATEGORY_RATIO_DESCRIPTION
                : FIELD_RATIO_DESCRIPTION
            }
            onChange={(event) => {
              setOccurrencesRatio(parseInt(event.target.value))
            }}
          />

          <TextField
            label="Field Accuracy Threshold"
            type="number"
            value={accuracyThreshold}
            disabled={!autoTrain}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
            helperText={
              isCategoryModel
                ? CATEGORY_ACCURACY_DESCRIPTION
                : FIELD_ACCURACY_DESCRIPTION
            }
            onChange={(event) => {
              setAccuracyThreshold(parseInt(event.target.value))
            }}
          />

          <Typography
            variant="caption"
            color={!autoTrain ? 'text.secondary' : undefined}
          >
            All conditions must be met before auto-training will occur.
          </Typography>

          {autoTrain && !conditionsAreMet && (
            <Typography variant="caption" sx={{ color: 'warning.main' }}>
              Please fill out all fields to enable auto-training.
            </Typography>
          )} 
        </Paper> */}
      </Stack>

      {projectModel && (
        <EditModelDialog
          detailQueryKey={projectModelQueryKey}
          overlay={overlay}
          model={projectModel}
        />
      )}
    </Container>
  )
}
