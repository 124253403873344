import { ReactNode, createContext, useContext } from 'react'
import { useGetWorkflowStates } from '@/service-library/hooks/workflow-states'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'
import { sortBySortOrder } from '@/utils/field-utils'

type WorkflowStatesContextValue = ReturnType<typeof useGetWorkflowStates>

const WorkflowStatesContext = createContext<WorkflowStatesContextValue>({
  workflowStates: [],
} as unknown as WorkflowStatesContextValue)

type WorkflowStatesProviderProps = {
  children: ReactNode
}

export const useWorkflowStatesContext = () => useContext(WorkflowStatesContext)

export default function WorkflowStatesProvider({
  children,
}: WorkflowStatesProviderProps) {
  const { selectedWorkflow } = useSelectedWorkflowContext()

  // ZACH TODO: project_model isn't available yet as a nested serializer, but we'll probably want it for multiple workflows list dropdown thing
  const query = useGetWorkflowStates({
    filters: {
      limit: '1000',
      workflow_id: selectedWorkflow?.id,
    },
    staleTime: 0, // Prevent firing off this request since we have initialData
    initialListData: selectedWorkflow?.workflow_states,
    enabled: !!selectedWorkflow,
  })

  return (
    <WorkflowStatesContext.Provider
      value={{
        ...query,
        workflowStates: sortBySortOrder(query.workflowStates),
      }}
    >
      {children}
    </WorkflowStatesContext.Provider>
  )
}
